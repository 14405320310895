import styled from '@emotion/styled'
import { Stack, Typography } from '@mui/material'
import { useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { observer } from 'mobx-react-lite'
import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/react'
import storeFeelGreat from 'GlobalStores/FeelGreat/StoreFeelGreat'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import { useState } from 'react'
import { ReactComponent as ArrowDisabled } from '../assets/pro-tips-arrow-disabled.svg'
import { ReactComponent as ArrowEnabled } from '../assets/pro-tips-arrow-enabled.svg'
import { ReactComponent as EmptyDot } from '../assets/empty-dot.svg'
import { ReactComponent as FilledDot } from '../assets/filled-dot.svg'
import { storeElement } from 'GlobalStores/StoreElement'

const ContainerProTips = styled(Stack)`
    width: 100%;
    padding: 60px 0px;
    background-color: #f7f2ef;
    justify-content: center;
    align-items: center;
`

const ContainerProTipsSlider = styled(Stack)`
    width: 315px;
    margin-top: 24px;
`

const ContainerSliderItem = styled(Stack)`
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
`

const ContainerSliderDots = styled(Stack)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 42px;
    gap: 17px;

    svg {
        &.active {
            fill: aliceblue;
        }
    }
`

const ProTips = observer(() => {
    const translate = useTranslate()
    const [currentSlide, setCurrentSlide] = useState(0)
    const [loaded, setLoaded] = useState(false)
    const [sliderRef, instanceRef] = useKeenSlider({
        initial: 0,
        slideChanged(slider) {
            setCurrentSlide(slider.track.details.rel)
        },
        created() {
            setLoaded(true)
        },
        loop: storeElement.dynamicWidth < 900 ? true : false
    })

    return (
        <ContainerProTips
            padding={{
                md: '120px 0px'
            }}>
            <Typography
                variant="h2"
                textAlign={'center'}
                sx={{
                    fontSize: {
                        xs: '24px',
                        sm: '32px',
                        md: '40px'
                    }
                }}>
                {translate('pro_tips')}
            </Typography>
            <ContainerProTipsSlider
                sx={{
                    width: {
                        xs: '315px',
                        md: '770px'
                    }
                }}
                position={'relative'}>
                <div ref={sliderRef} className="keen-slider">
                    {storeFeelGreat.GetContent('learnPage.proTips.items', []).map((item, index) => (
                        <div key={index} className={`keen-slider__slide`}>
                            <ContainerSliderItem
                                sx={{
                                    flexDirection: {
                                        md: 'row'
                                    }
                                }}>
                                <Stack padding={'30px'} flex={1} minHeight={'155px'}>
                                    <Typography
                                        variant="h5"
                                        fontSize={{
                                            xs: '20px',
                                            md: '24px'
                                        }}
                                        fontFamily={'Poppins, Inter, NotoSansThai'}
                                        fontWeight={600}>
                                        {StoreTranslate.UseLanguage(item.title)}
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        fontFamily={'Inter, Poppins, NotoSansThai'}
                                        style={{ fontWeight: 400 }}
                                        fontSize={{
                                            xs: '14px',
                                            md: '18px'
                                        }}
                                        mt="16px">
                                        {StoreTranslate.UseLanguage(item.description)}
                                    </Typography>
                                </Stack>
                                <Stack>
                                    <img src={item.imageUrl} alt={StoreTranslate.UseLanguage(item.title)} style={{ objectFit: 'cover', height: '285px' }} />
                                </Stack>
                            </ContainerSliderItem>
                        </div>
                    ))}
                </div>
                {loaded && instanceRef.current && storeElement.dynamicWidth >= 900 && (
                    <>
                        <Arrow
                            left
                            onClick={e => e.stopPropagation() || instanceRef.current?.prev()}
                            disabled={currentSlide === 0}
                            style={{ transform: 'rotate(-180deg)' }}
                        />

                        <Arrow
                            onClick={e => e.stopPropagation() || instanceRef.current?.next()}
                            disabled={currentSlide === instanceRef.current.track.details.slides.length - 1}
                        />
                    </>
                )}
                {loaded && instanceRef.current && (
                    <ContainerSliderDots className="dots">
                        {[...Array(instanceRef.current.track.details.slides.length).keys()].map(idx => {
                            if (currentSlide === idx) {
                                return (
                                    <FilledDot
                                        key={idx}
                                        onClick={() => {
                                            instanceRef.current?.moveToIdx(idx)
                                        }}
                                        className={'dot' + (currentSlide === idx ? ' active' : '')}
                                    />
                                )
                            } else {
                                return (
                                    <EmptyDot
                                        key={idx}
                                        onClick={() => {
                                            instanceRef.current?.moveToIdx(idx)
                                        }}
                                        className={'dot' + (currentSlide === idx ? ' active' : '')}
                                    />
                                )
                            }
                        })}
                    </ContainerSliderDots>
                )}
            </ContainerProTipsSlider>
        </ContainerProTips>
    )
})

const Arrow = props => {
    if (props.disabled) {
        return (
            <ArrowDisabled
                onClick={props.onClick}
                style={{
                    cursor: 'pointer',
                    width: '30px',
                    height: '30px',
                    position: 'absolute',
                    top: '45%',
                    transform: props.left ? 'rotate(180deg) translateY(25%)' : 'rotate(0deg) translateY(-65%)',
                    left: props.left ? '-60px' : 'auto',
                    right: props.left ? 'auto' : '-60px',
                    g: {
                        path: {
                            fill: props.disabled ? '#c4c4c4' : '#003764'
                        }
                    }
                }}
            />
        )
    } else {
        return (
            <ArrowEnabled
                onClick={props.onClick}
                style={{
                    cursor: 'pointer',
                    width: '30px',
                    height: '30px',
                    position: 'absolute',
                    top: '45%',
                    transform: props.left ? 'rotate(180deg) translateY(25%)' : 'rotate(0deg) translateY(-65%)',
                    left: props.left ? '-60px' : 'auto',
                    right: props.left ? 'auto' : '-60px',
                    g: {
                        path: {
                            fill: props.disabled ? '#c4c4c4' : '#003764'
                        }
                    }
                }}
            />
        )
    }
}

export default ProTips
