import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import storeFeelGreat from 'GlobalStores/FeelGreat/StoreFeelGreat'
import { observer } from 'mobx-react-lite'
import { ReactComponent as TitleIcon } from '../assets/unimate-icon.svg'
import { ReactComponent as PlusIcon } from '../assets/plus-handwritten.svg'
import { ReactComponent as MinusIcon } from '../assets/minus-handwritten.svg'
import { useState } from 'react'
import styled from '@emotion/styled'

const ContentContainer = styled(Stack)`
    margin-top: 30px;
    padding-bottom: 6px;
    gap: 24px 30px;
`

const ContentContainerItem = styled(Stack)`
    gap: 16px;
    p {
        margin: 0;
    }
    img {
        width: 100%;
        @media screen and (min-width: 1200px) {
            height: 150px;
        }
    }
`

const ThreeSimpleSteps = observer(() => {
    const translate = useTranslate()
    const [expanededs, setExpandeds] = useState({
        panel1: true,
        panel2: false,
        panel3: false
    })

    const styles = [
        {
            backgroundColor: '#FBF7F1',
            iconColor: '#FFC24C'
        },
        {
            backgroundColor: 'rgba(254, 226, 208, 0.5)',
            iconColor: '#FFBD92',
            cover: ''
        },
        {
            backgroundColor: '#F1F6FB',
            iconColor: '#99BCDF'
        }
    ]

    return (
        <Stack
            sx={{
                padding: {
                    xs: '60px 30px',
                    sm: '80px 40px'
                },
                backgroundColor: '#ffffff',
                width: '100%',
                boxSizing: 'border-box',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
            <Typography
                variant="h3"
                textAlign={'center'}
                sx={{
                    fontSize: {
                        xs: '28px',
                        sm: '28px',
                        md: '40px'
                    }
                }}
                marginBottom={'20px'}>
                {translate('three_simple_steps')}
            </Typography>
            <Stack
                sx={{
                    paddingTop: {
                        xs: '40px'
                    },
                    gap: '20px',
                    width: {
                        xs: '100%',
                        sm: '100%',
                        md: '770px',
                        lg: '770px'
                    }
                }}>
                {storeFeelGreat.GetContent('learnPage.threeSimpleSteps.items', []).map((step, index) => (
                    <Accordion
                        expanded={expanededs[`panel${index + 1}`] || false}
                        key={index}
                        sx={{
                            backgroundColor: styles[index].backgroundColor,
                            boxShadow: 'none',
                            '&::before': {
                                display: 'none'
                            },
                            borderRadius: '16px!important',
                            marginTop: '0!important',
                            '&.Mui-expanded': {
                                marginBottom: 0
                            }
                        }}
                        onChange={() => {
                            setExpandeds({
                                ...expanededs,
                                [`panel${index + 1}`]: !expanededs[`panel${index + 1}`]
                            })
                        }}>
                        <AccordionSummary
                            sx={{
                                height: '87px',
                                padding: {
                                    xs: '0 30px',
                                    sm: '0 30px',
                                    md: '0 30px',
                                    lg: '0 50px'
                                }
                            }}
                            expandIcon={expanededs[`panel${index + 1}`] ? <MinusIcon /> : <PlusIcon />}>
                            <Typography variant="h5" color={'#153862'} fontWeight={600} fontFamily={'Poppins'}>
                                {StoreTranslate.UseLanguage(step.title)}
                            </Typography>
                            <Stack
                                ml="8px"
                                sx={{
                                    svg: {
                                        path: {
                                            fill: styles[index].iconColor
                                        }
                                    }
                                }}>
                                <TitleIcon />
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{
                                padding: {
                                    xs: '0 30px',
                                    sm: '0 30px',
                                    md: '0 30px',
                                    lg: '0 50px'
                                },
                                transform: 'translateY(-24px)'
                            }}>
                            <Typography variant="body2">{StoreTranslate.UseLanguage(step.description)}</Typography>
                            <ContentContainer
                                sx={{
                                    flexDirection: {
                                        xs: 'column',
                                        sm: 'column',
                                        md: 'column',
                                        lg: 'row'
                                    },
                                    display: {
                                        xs: 'flex',
                                        sm: 'flex',
                                        md: 'grid'
                                    },
                                    gridTemplateColumns: '1fr 1fr'
                                }}>
                                {step.stepItems.map(item => {
                                    return (
                                        <ContentContainerItem key={item.title}>
                                            <img src={item.imageUrl} alt={item.title} />
                                            <Typography
                                                variant="body2"
                                                component={'div'}
                                                dangerouslySetInnerHTML={{ __html: StoreTranslate.UseLanguage(item.text) }}
                                                textAlign={'center'}
                                                sx={{
                                                    fontSize: {
                                                        xs: '14px',
                                                        sm: '14px',
                                                        md: '14px',
                                                        lg: '16px'
                                                    },
                                                    p: {
                                                        marginTop: '16px'
                                                    }
                                                }}
                                            />
                                        </ContentContainerItem>
                                    )
                                })}
                            </ContentContainer>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Stack>
        </Stack>
    )
})

export default ThreeSimpleSteps
