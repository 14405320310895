import { Card, CardActions, CardContent, CardMedia } from '@mui/material'
import { observer } from 'mobx-react-lite'

const CardWithPicture = observer(props => {
    return (
        <Card style={props.style || {}} className="card--with-picture">
            <CardMedia
                component="img"
                alt={props.image?.alt}
                height={props.image?.height}
                width={props.image?.width}
                image={props.image?.url || props.image}
                style={{ borderRadius: '12px' }}
            />
            <CardContent style={props.cardContentStyle || {}}>{props.children}</CardContent>
            <CardActions style={props.CardActionStyle || {}}>{props.footer}</CardActions>
        </Card>
    )
})

export default CardWithPicture
