import { Box, styled, Typography } from '@mui/material'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import storeFeelGreat from 'GlobalStores/FeelGreat/StoreFeelGreat'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'

const AppComponent = observer(() => {
    const appData = toJS(storeFeelGreat.GetContent('appPage'))

    return (
        <>
            {/* Head Banner */}
            <HeaderWrapper
                sx={{
                    backgroundImage: `url(${appData.bannerImageDesktopUrl})`,

                    '@media (min-width: 350px) and (max-width: 800px)': {
                        backgroundImage: `url(${appData.bannerImageMobileUrl})`
                    }
                }}>
                <Box
                    sx={{
                        flex: '1',
                        marginLeft: '15px',
                        textAlign: 'center',

                        '@media (min-width: 350px) and (max-width: 800px)': {
                            textAlign: 'left'
                        }
                    }}>
                    <Typography variant="h2">{StoreTranslate.UseLanguage(appData.title)}</Typography>
                    <Typography variant="h6">{StoreTranslate.UseLanguage(appData.secondTitle)}</Typography>
                    <Box
                        sx={{
                            width: '100%',
                            maxWidth: '500px',
                            justifySelf: 'center',
                            '@media (min-width: 350px) and (max-width: 800px)': {
                                width: '50%',
                                justifySelf: 'left'
                            }
                        }}>
                        <Typography variant="h4">{StoreTranslate.UseLanguage(appData.description)}</Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        flex: 1,
                        justifyContent: 'center',
                        padding: '30px',
                        display: 'block',

                        '@media (min-width: 350px) and (max-width: 800px)': {
                            display: 'none'
                        }
                    }}>
                    <img src={appData.appImageUrl ? appData.appImageUrl : `https://ufeelgreat.com/img/fastingApp.svg`} alt="" height="250px" />
                </Box>
            </HeaderWrapper>
            <Box
                sx={{
                    flex: 1,
                    justifyContent: 'center',
                    textAlign: 'center',
                    padding: '20px',
                    display: 'none',

                    '@media (min-width: 350px) and (max-width: 800px)': {
                        display: 'block'
                    }
                }}>
                <img src={appData.appImageUrl ? appData.appImageUrl : `https://ufeelgreat.com/img/fastingApp.svg`} alt="" />
            </Box>

            {/* Application */}
            <Box
                sx={{
                    color: '#0b3b61',
                    textAlign: 'center',
                    minHeight: '400px',
                    padding: '0px 35px'
                }}>
                <Box
                    sx={{
                        width: '50%',
                        margin: 'auto',

                        '@media (min-width: 350px) and (max-width: 800px)': {
                            width: '100%'
                        }
                    }}>
                    <Typography
                        variant="h7"
                        sx={{
                            p: {
                                span: {
                                    color: '#0b3b61 !important'
                                }
                            }
                        }}
                        dangerouslySetInnerHTML={{
                            __html: StoreTranslate.UseLanguage(appData.appDescription)
                        }}
                    />

                    <Box
                        sx={{
                            display: 'flex',
                            flexFlow: 'wrap',
                            justifyContent: 'center',
                            gap: '20px'
                        }}>
                        <a href={appData.appStoreLink} rel="noreferrer" target="_blank">
                            <img
                                src={appData.appStoreImageUrl ? appData.appStoreImageUrl : `https://ufeelgreat.com/svg/app.svg`}
                                alt="App store"
                                height="100px"
                            />
                        </a>

                        <a href={appData.googlePlayLink} rel="noreferrer" target="_blank">
                            <img
                                src={appData.googlePlayImageUrl ? appData.googlePlayImageUrl : `https://ufeelgreat.com/svg/google.svg`}
                                alt="Play store"
                                height="100px"
                            />
                        </a>
                    </Box>
                </Box>
            </Box>
        </>
    )
})

const HeaderWrapper = styled(Box)`
    min-height: 400px;
    background-color: #f8f3ed;
    background-repeat: no-repeat;
    background-position: right;

    align-items: center;

    display: flex;
    flex-direction: row;
    position: relative;
    flex: 1;
    justify-content: space-between;

    @media (min-width: 350px) and (max-width: 800px) {
        min-height: 300px;
        background-size: cover;
        background-image: url(https://ufeelgreat.com/img/mobile-banner.png);
    }
`

export default AppComponent
