import { ThemeProvider } from '@mui/material'
import { MainTemplate } from 'Components/Template/MainTemplate'
import StoreCheckout from 'GlobalStores/Checkout/StoreCheckout'
import StoreFeelGreat from 'GlobalStores/FeelGreat/StoreFeelGreat'
import { observer } from 'mobx-react-lite'
import FrequentlyAskedQuestions from '../Elements/Landings/Components/FrequentlyAskedQuestions/FrequentlyAskedQuestions'
import TheProducts from '../Elements/Landings/Components/TheProducts/TheProducts'
import MainLayout from '../Layouts/MainLayout'
import ReferralBar from 'Components/Elements/Referral/ReferralBar'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import ProductDetailSelection from 'Components/Elements/Products/Components/ProductDetailSelection'
import Banner from 'Components/Elements/Landings/Components/Banner/Banner'
import { toJS } from 'mobx'
import { get } from 'lodash'
import LoginWrapper from './LoginWrapper'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import { useEffect } from 'react'
import trackingEvents from 'Services/Tracker/TrackingEvents'

const Products = observer(() => {
    const allLayoutComponent = StoreCheckout.CheckOutInstance().GetProductLayout() || []

    useEffect(() => {
        trackingEvents.ViewItems(trackingEvents.EVENT_TYPE.VIEW_ITEM.toLowerCase())
    }, [])

    const components = {
        banner: Banner,
        productDetailSelection: ProductDetailSelection,
        theProducts: TheProducts,
        frequentlyAskedQuestions: FrequentlyAskedQuestions
    }

    return (
        <ThemeProvider theme={MainTemplate}>
            <LoginWrapper>
                {!StoreAuth.IsAuthorized() && <ReferralBar isProductPage />}
                {allLayoutComponent.map(component => {
                    if (['JPN'].includes(storeCountry.Country3()) && component.nameComponent === 'banner') return false
                    const isProductSelect = component.nameComponent === 'productDetailSelection'
                    const SectionComponent = components[component.nameComponent]
                    const dataContent = toJS(StoreFeelGreat.GetContent(component.titleContent))
                    const isEnableSection = get(dataContent, 'isEnable')
                    return (
                        <MainLayout
                            isEnable={isProductSelect ? true : dataContent && isEnableSection}
                            style={{ ...component.style }}
                            key={component.nameComponent}>
                            <SectionComponent data={dataContent} />
                        </MainLayout>
                    )
                })}
            </LoginWrapper>
        </ThemeProvider>
    )
})

export default Products
