import { ObjectHelpers } from 'Helpers/ObjectHelpers'
import { FeelGreatAPI } from 'Services/FeelGreat/APIs'
import StoreCountry from './Cores/County/StoreCountry'
import StoreAuth from './User/StoreAuth'
import { omit } from 'lodash'

const PURCHASE_ONE_TIME = 'onetime'
const PURCHASE_SUBSCRIBE = 'subscribe'

class Email {
    type = 'enroll' // shop
    shippingType = 'delivery' //pickup, aramex
    purchaseType = PURCHASE_SUBSCRIBE // oneTime
    emailData = null

    toEmail = ''
    title = ''
    subtitle = ''
    bcc = 'unicity.dev2018@gmail.com'

    enrollId = ''
    enrollName = ''
    enrollDate = ''

    orderId = ''
    pvMonth = ''

    shipToAddress = ''
    shipToName = ''
    shipToNameEn = ''
    shipToNameNative = ''

    productItems = ''

    memberId = ''
    profilePicture = ''

    autoshipNumber = ''

    totalPrice = ''

    EnrollEmailSubscribe() {
        return {
            to_email: this.toEmail,
            bcc: this.bcc,
            type: 'enroll',
            order_id: this.orderId,
            member_id: this.memberId.toString(),
            ship_to_name: this.shipToName,
            ship_to_address: this.shipToAddress,
            product_items: JSON.stringify(this.productItems),
            shipment_purchas_option: PURCHASE_SUBSCRIBE,
            shipping_type: this.shippingType,
            autoship: {
                autoship_number: this.autoshipNumber
            }
        }
    }

    EnrollEmailOneTime() {
        return {
            to_email: this.toEmail,
            bcc: this.bcc,
            type: 'enroll',
            order_id: this.orderId,
            member_id: this.memberId.toString(),
            ship_to_name: this.shipToName,
            ship_to_address: this.shipToAddress,
            product_items: JSON.stringify(this.productItems),
            shipment_purchas_option: PURCHASE_ONE_TIME,
            shipping_type: this.shippingType
        }
    }

    ShopEmailSubscribe() {
        const data = {
            to_email: this.toEmail,
            bcc: this.bcc,
            type: 'shop',
            order_id: this.orderId,
            member_id: this.memberId.toString(),
            ship_to_name: this.shipToName,
            ship_to_address: this.shipToAddress,
            product_items: JSON.stringify(this.productItems),
            shipment_purchas_option: PURCHASE_SUBSCRIBE,
            shipping_type: this.shippingType,
            autoship: {
                autoship_number: this.autoshipNumber
            }
        }
        if (!ObjectHelpers.isEmpty(this.profilePicture)) {
            data.profile_picture = this.profilePicture
        }

        return data
    }

    ShopEmailOneTime() {
        return {
            to_email: this.toEmail,
            bcc: this.bcc,
            type: 'shop',
            order_id: this.orderId,
            member_id: this.memberId.toString(),
            ship_to_name: this.shipToName,
            ship_to_address: this.shipToAddress,
            product_items: this.productItems,
            shipping_type: 'delivery',
            total_price: this.totalPrice,
        }
    }

    ShopEmail() {
        return {
            to_email: this.toEmail,
            bcc: this.bcc,
            type: 'shop',
            order_id: this.orderId,
            member_id: this.memberId.toString(),
            ship_to_name: this.shipToName,
            ship_to_address: this.shipToAddress,
            product_items: this.productItems,
            shipping_type: 'delivery',
            total_price: this.totalPrice,
            autoship: {
                autoship_number: this.autoshipNumber
            }
        }
    }

    SetTotalPrice(totalPrice) {
        this.totalPrice = totalPrice
        return this
    }

    SetToEmail(email) {
        this.toEmail = email
        return this
    }

    SetType(type) {
        this.type = type
        return this
    }

    SetPurchaseOption(purchase) {
        this.purchaseType = purchase
        return this
    }

    SetMemberId(memberId) {
        this.memberId = memberId
        return this
    }

    SetShipTo(shipToName, shipToAddress) {
        this.shipToName = shipToName
        this.shipToAddress = shipToAddress
        return this
    }

    SetShipToForJPN({ shipToNameEn, shipToNameNative, shipToAddress }) {
        this.shipToNameEn = shipToNameEn
        this.shipToNameNative = shipToNameNative
        this.shipToAddress = shipToAddress
        return this
    }

    SetOrderId(orderId) {
        this.orderId = orderId
        return this
    }

    SetProductItems(productItems) {
        this.productItems = productItems
        return this
    }

    SetProfilePicture(profilePicture) {
        this.profilePicture = profilePicture
        return this
    }

    SetShippingType(shipping) {
        this.shippingType = shipping
        return this
    }

    SetAutoship(autoshipNumber) {
        this.autoshipNumber = autoshipNumber
        return this
    }

    Send(isLambda = false) {
        let data = this.ShopEmail()

        let convertPrdItem = JSON.stringify(data['product_items'])
        let purchaseType = 'onetime'

        if (convertPrdItem.hasOwnProperty('subscribe')) {
            purchaseType = 'subscribe'
        }

        if (StoreCountry.Country3() === 'AUS') {
            data['purchaseType'] = purchaseType
            data['action'] = 'orderConfirmation'
            data['baStatus'] = 'C'

            if (['A', 'M'].includes(StoreAuth.userStatus)) {
                data['baStatus'] = 'M'
            }
        }
        let finalData = isLambda ? omit(data, ['order_id']) : data
        // console.log('email was sent', finalData)
        if (data) {
            FeelGreatAPI.SendEmailV3(finalData, StoreCountry.Country3())
        }
    }

    SendV4(isLambda = false) {
        let data = null

        if (this.autoshipNumber) {
            data = this.ShopEmail()
        } else {
            data = this.ShopEmailOneTime()
        }

        let convertPrdItem = JSON.stringify(data['product_items'])
        let purchaseType = 'onetime'

        if (convertPrdItem.hasOwnProperty('subscribe')) {
            purchaseType = 'subscribe'
        }

        // if (StoreCountry.Country3() === 'AUS') {
        //     data['purchaseType'] = purchaseType
        //     data['action'] = 'orderConfirmation'
        //     data['baStatus'] = 'C'

        //     if (['A', 'M'].includes(StoreAuth.userStatus)) {
        //         data['baStatus'] = 'M'
        //     }
        // }

        switch (StoreCountry.Country3()) {
            case 'AUS': {
                data['purchaseType'] = purchaseType
                data['action'] = 'orderConfirmation'
                data['baStatus'] = 'C'

                if (['A', 'M'].includes(StoreAuth.userStatus)) {
                    data['baStatus'] = 'M'
                }
                break;
            }
            case 'JPN': {
                delete data['ship_to_name']
                data['ship_to_name_en'] = this.shipToNameEn
                data['ship_to_name_native'] = this.shipToNameNative
                break;
            }
            default: {
                break;
            }
        }


        let finalData = isLambda ? omit(data, ['order_id']) : data
        // console.log('email was sent', finalData)
        if (data) {
            FeelGreatAPI.SendEmailV4(finalData, StoreCountry.Country3())
        }
    }

    constructor(type, purchaseType, emailData) {
        this.type = type
        this.emailData = emailData
        this.purchaseType = purchaseType
    }
}

export default Email
