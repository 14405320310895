import { get, isEmpty } from 'lodash'
import { makeAutoObservable, runInAction } from 'mobx'

class StoreFormat {
    data = {
        hydra: {},
        ushop: {}
    }

    frontU23details = {}

    FrontSideUpdate(data) {
        this.frontU23details = data
        return runInAction(() => (this.data.ushop.u23 = data))
    }

    GetDetailPage() {
        return get(this.data, 'ushop.detail_page', {})
    }

    GetFormatData(key) {
        let defaultKey = 'ushop.u23'
        if (key) {
            defaultKey = defaultKey.split('.')
            defaultKey.push(key)
            defaultKey = defaultKey.join('.')
        }

        return get(this.data, defaultKey, [])
    }

    GetMemberDiscount() {
        return this.GetFormatData('formatSaving.member_discount.value') === null ? 0 : parseInt(this.GetFormatData('formatSaving.member_discount.value'))
    }
    GetSubscriptionDiscount() {
        return this.GetFormatData('formatSaving.subscription_discount.value') === null ? 0 : parseInt(this.GetFormatData('formatSaving.subscription_discount.value'))
    }

    GetFreight() {
        return get(this.data, 'hydra.marketing.terms.freight.amount', null)
    }

    async SetData(value) {
        if (!isEmpty(this.frontU23details)) {
            value.ushop.u23['formatProducts'] = this.frontU23details['formatProducts']
            value.ushop.u23['formatSaving'] = this.frontU23details['formatSaving']
        }
        return runInAction(() => (this.data = value))
    }

    constructor() {
        makeAutoObservable(this)
    }
}
const storeFormat = new StoreFormat()
export default storeFormat
