import Wave from '../assets/wave.svg'
import VideoCover from '../assets/us-how-to-feel-great-en.png'
import { ReactComponent as PlayController } from '../assets/play.svg'
import { ReactComponent as PauseController } from '../assets/pause.svg'
import styled from '@emotion/styled'
import { Button, Fade, Stack, Typography } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import storeFeelGreat from 'GlobalStores/FeelGreat/StoreFeelGreat'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import { reaction } from 'mobx'

const ContentBanner = styled(Stack)`
    flex-direction: column-reverse;
    background-color: #fbf7f1;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    box-sizing: border-box;
    p {
        margin: 0;
    }
    @media screen and (min-width: 768px) {
        align-items: center;
        width: 100%;
    }

    @media screen and (min-width: 900px) {
        flex-direction: row;
    }
`

const H2Styled = styled(Stack)`
    margin: 0;
    color: #0b3b61;
    font-weight: 600;
    font-family: Poppins;
    font-size: 33px;

    p {
        margin: 0;
        position: relative;

        &:last-of-type {
            text-decoration: none;
            position: relative;
            display: block;
            width: max-content;
            font-family: Poppins;
            line-height: 4.75rem;
            margin-top: -14px;
            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: -1%;
                width: 102%;
                height: 20px;
                background-image: url(${Wave});
                background-size: 100% 100%;
            }
        }

        @media screen and (min-width: 768px) {
            text-align: center;
        }
    }

    @media screen and (min-width: 768px) {
        line-height: 4.75rem;
        align-items: center;
        p {
            text-align: center;
        }
    }
    @media screen and (min-width: 900px) {
        align-items: flex-start;
        white-space: nowrap;
        p {
            text-align: left;
            font-size: 60px;
            &:last-of-type {
                margin-top: 0;
            }
        }
    }
`

const ImageSection = () => {
    const vidRef = useRef()
    const [isPlayingVideo, setIsPlayingVideo] = useState(false)
    const [showController, setShowController] = useState(true)
    const [isStarted, setIsStarted] = useState(false)
    const [, setTrigger] = useState(0)

    const onplay = () => {
        if (!vidRef.current) {
            return
        }

        if (isPlayingVideo) {
            vidRef.current.pause()
            setIsPlayingVideo(false)
            setShowController(true)
        } else {
            vidRef.current.play()
            setIsPlayingVideo(true)
            setShowController(false)
        }

        if (!isStarted) {
            setIsStarted(true)
        }
    }

    const handleMouseEnter = () => {
        if (showController !== true) {
            setShowController(true)
        }
    }

    const handleMouseLeave = () => {
        setShowController(false)
    }

    useEffect(() => {
        const disposerLanguage = reaction(
            () => StoreTranslate.CurrentLanguage(),
            (n, p) => {
                if (n !== p) {
                    setTrigger(Math.random())
                }
            }
        )

        return () => {
            disposerLanguage()
        }
    }, [])

    return (
        <ContentBanner
            sx={{
                padding: {
                    xs: '60px 32px',
                    sm: '80px calc((100vw - 310px) / 2)',
                    md: '80px 135px'
                },
                height: {
                    xs: 'auto',
                    sm: 'auto',
                    md: '560px',
                    lg: '560px'
                },
                width: '100%'
            }}>
            <Stack
                width={'fit-content'}
                sx={{
                    gap: {
                        xs: '16px',
                        sm: '16px',
                        md: '40px',
                        lg: '40px'
                    }
                }}>
                <H2Styled
                    dangerouslySetInnerHTML={{ __html: StoreTranslate.UseLanguage(storeFeelGreat.GetContent('learnPage.imageSection.title')) }}></H2Styled>
                <Stack
                    sx={{
                        marginTop: '16px',
                        alignItems: {
                            xs: 'flex-start',
                            sm: 'center',
                            md: 'flex-start'
                        }
                    }}>
                    <Typography variant="caption3" style={{ fontSize: '1rem' }}>
                        {StoreTranslate.UseLanguage(storeFeelGreat.GetContent('learnPage.imageSection.description'))}
                    </Typography>
                </Stack>
                <Stack
                    sx={{
                        marginTop: '8px',
                        alignItems: {
                            xs: 'flex-start',
                            sm: 'center',
                            md: 'flex-start'
                        }
                    }}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{
                            borderRadius: 34,
                            padding: '6px 33px',
                            minWidth: 0,
                            width: 'fit-content',
                            boxShadow: 'none',
                            fontWeight: 600,
                            fontSize: 20,
                            fontFamily: 'Poppins',
                            textTransform: 'none'
                        }}
                        onClick={() => onplay()}>
                        {StoreTranslate.UseLanguage(storeFeelGreat.GetContent('learnPage.imageSection.textButton'))}
                    </Button>
                </Stack>
            </Stack>
            <Stack
                borderRadius={'16px'}
                overflow={'hidden'}
                position={'relative'}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                sx={{
                    width: {
                        xs: '366px',
                        sm: '366px',
                        md: '400px',
                        lg: '570px'
                    },
                    height: {
                        xs: '235px',
                        sm: '198px',
                        md: '256px',
                        lg: '365px'
                    }
                }}>
                <img src={StoreTranslate.UseLanguage(storeFeelGreat.GetContent('learnPage.imageSection.imageUrl'))} alt="" />
            </Stack>
        </ContentBanner>
    )
}

export default ImageSection
