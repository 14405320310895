import { Stack, ThemeProvider, Typography } from '@mui/material'
import { MainTemplate } from 'Components/Template/MainTemplate'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import storeFeelGreat from 'GlobalStores/FeelGreat/StoreFeelGreat'
import { observer } from 'mobx-react-lite'
import { ReactComponent as FastingAppIcon } from './fastingApp.svg'
import { ReactComponent as GooglePlayIcon } from './google.svg'
import { ReactComponent as AppStoreIcon } from './apple.svg'

const FastingApp = observer(() => {
    return (
        <ThemeProvider theme={MainTemplate}>
            <Stack
                sx={{
                    backgroundImage: {
                        xs: `url(${storeFeelGreat.GetContent('appPage.bannerImageMobileUrl')})`,
                        sm: `url(${storeFeelGreat.GetContent('appPage.bannerImageDesktopUrl')})`
                    },
                    backgroundSize: 'cover',
                    paddingLeft: '15px',
                    minHeight: '300px',
                    boxSizing: 'border-box',
                    justifyContent: 'center',
                    backgroundPosition: 'right'
                }}
                display={{
                    xs: 'flex',
                    md: 'none'
                }}>
                <Stack>
                    <Typography
                        variant="h2"
                        sx={{
                            fontSize: {
                                xs: '33px'
                            },
                            fontWeight: 600,
                            fontFamily: 'Poppins, NotoSansThai, Inter'
                        }}>
                        {StoreTranslate.UseLanguage(storeFeelGreat.GetContent('appPage.title'))}
                    </Typography>
                    <Typography
                        variant="h2"
                        sx={{
                            fontSize: {
                                xs: '25px'
                            },
                            fontWeight: 400,
                            marginBottom: '1rem',
                            fontFamily: 'Poppins, NotoSansThai, Inter'
                        }}>
                        {StoreTranslate.UseLanguage(storeFeelGreat.GetContent('appPage.secondTitle'))}
                    </Typography>
                </Stack>
                <Stack width={'50%'}>
                    <Typography
                        variant="h5"
                        component={'p'}
                        sx={{
                            fontFamily: 'Inter, Poppins, NotoSansThai',
                            fontSize: {
                                xs: '16px',
                                md: '18px'
                            },
                            fontWeight: 400
                        }}>
                        {StoreTranslate.UseLanguage(storeFeelGreat.GetContent('appPage.description'))}
                    </Typography>
                </Stack>
            </Stack>
            <Stack
                sx={{
                    backgroundImage: {
                        xs: `url(${storeFeelGreat.GetContent('appPage.bannerImageDesktopUrl')})`
                    },
                    backgroundPosition: 'right',
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: '#f8f3ed',
                    minHeight: '400px',
                    boxSizing: 'border-box',
                    flexDirection: 'row',
                    flex: 1
                }}
                display={{
                    xs: 'none',
                    md: 'flex'
                }}>
                <Stack sx={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                    <Typography
                        variant="h2"
                        sx={{
                            color: '#0b3b61',
                            fontSize: '60px',
                            fontWeight: 600,
                            fontFamily: 'Poppins, NotoSansThai, Inter',
                            textAlign: 'center'
                        }}>
                        {StoreTranslate.UseLanguage(storeFeelGreat.GetContent('appPage.title'))}
                    </Typography>
                    <Typography
                        variant="h2"
                        sx={{
                            fontSize: '40px',
                            fontWeight: 400,
                            marginBottom: '1rem',
                            fontFamily: 'Poppins, NotoSansThai, Inter',
                            textAlign: 'center'
                        }}>
                        {StoreTranslate.UseLanguage(storeFeelGreat.GetContent('appPage.secondTitle'))}
                    </Typography>
                    <Typography
                        variant="h5"
                        component={'p'}
                        sx={{
                            fontFamily: 'Inter, Poppins, NotoSansThai',
                            fontSize: {
                                xs: '16px',
                                md: '18px'
                            },
                            fontWeight: 400,
                            textAlign: 'center',
                            width: '550px'
                        }}>
                        {StoreTranslate.UseLanguage(storeFeelGreat.GetContent('appPage.description'))}
                    </Typography>
                </Stack>
                <Stack
                    sx={{
                        flex: 1,
                        padding: '30px',
                        justifyContent: 'center',
                        img: {
                            width: '250px'
                        }
                    }}>
                    <img src={require('./fastingApp.svg').default} alt="fastingApp" />
                </Stack>
            </Stack>
            <Stack
                alignItems={'center'}
                padding={'20px'}
                display={{
                    xs: 'flex',
                    md: 'none'
                }}>
                <FastingAppIcon />
            </Stack>
            <Stack padding={'0px 35px'}>
                <Typography
                    variant="body"
                    component={'p'}
                    dangerouslySetInnerHTML={{ __html: StoreTranslate.UseLanguage(storeFeelGreat.GetContent('appPage.appDescription')) }}
                    fontSize={{
                        xs: '1.5rem',
                        md: '2rem'
                    }}
                    fontWeight={{
                        xs: 300,
                        md: 400
                    }}
                    fontFamily={'Poppins, Inter, NotoSansThai'}
                    textAlign={'center'}
                    sx={{
                        span: {
                            color: 'rgb(11, 59, 97)!important'
                        },
                        strong: {
                            color: 'rgb(11, 59, 97)!important'
                        }
                    }}
                />
            </Stack>
            <Stack
                alignItems={{
                    xs: 'center',
                    md: 'flex-start'
                }}
                justifyContent={{
                    xs: 'flex-start',
                    md: 'center'
                }}
                gap={'20px'}
                mb="60px"
                flexDirection={{
                    xs: 'column',
                    md: 'row'
                }}
                minHeight={{
                    xs: '0',
                    sm: '30vh'
                }}>
                <a href={storeFeelGreat.GetContent('appPage.appStoreLink')} target="_blank" rel="noreferrer">
                    <AppStoreIcon />
                </a>
                <a href={storeFeelGreat.GetContent('appPage.googlePlayLink')} target="_blank" rel="noreferrer">
                    <GooglePlayIcon />
                </a>
            </Stack>
        </ThemeProvider>
    )
})

export default FastingApp
