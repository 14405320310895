import styled from '@emotion/styled'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Stack, Typography } from '@mui/material'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import { runInAction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { ReactComponent as PlusIcon } from '../assets/plus-handwritten.svg'
import { ReactComponent as MinusIcon } from '../assets/minus-handwritten.svg'
import storeFeelGreat from 'GlobalStores/FeelGreat/StoreFeelGreat'

const AccordionStyled = styled(Accordion)`
    border: ${props => (props.isselected === 'true' ? '2px solid #99bcdf' : '1px solid rgba(153, 188, 223, 0.25)')};
    border-radius: 6px;
    background-color: transparent;
    box-shadow: none;
    margin: 0px;
    &.MuiPaper-root.MuiAccordion-root {
        &.Mui-expanded {
            margin: 0px;
        }
    }
`

const AccordionSummaryStyled = styled(AccordionSummary)`
    &.MuiButtonBase-root {
        &.MuiAccordionSummary-root {
            padding: 0;
            min-height: auto;
            .MuiAccordionSummary-content {
                padding: 0;
                margin: 20px 0px;
            }
        }
    }
`

const AccordionDetailsStyled = styled(AccordionDetails)`
    &.MuiAccordionDetails-root {
        padding: 0;
        padding-bottom: 20px;
    }
`

const GroupButtonTab = styled(Box)`
    width: 100%;
    margin-bottom: 40px;
    display: inline-block;
    gap: 16px;
    justify-content: space-between;
    white-space: nowrap;
    gap: 30px;
`

const ButtonTabStyle = styled(Button)`
    height: 44px;
    min-width: 90px;
    width: fit-content;
    text-transform: capitalize;
    box-sizing: border-box;
    padding: 11px;
    &.MuiButton-outlined {
        border: ${props => (props.isselected === 'true' ? '2px solid #99bcdf' : '1px solid rgba(153, 188, 223, 0.25)')};
        border-radius: 6px;
    }
`

const ListComponentStyled = styled(Stack)`
    width: 100%;
    align-self: center;
    margin-bottom: 40px;
`

const Faq = observer(() => {
    const [state, setState] = useState({ groupKey: 0, itemKey: 0 })
    const [, setLoading] = useState(true)
    const data = useLocalObservable(() => ({
        questions: [],
        titleGroup: [],
        items: [],
        selectedGroup: 0,
        selectedItems: {}
    }))

    const handleChange = newAlignment => {
        runInAction(() => {
            data.selectedGroup = newAlignment
        })

        setState({
            groupKey: newAlignment,
            itemKey: 0
        })
    }

    useEffect(() => {
        setTimeout(() => {
            if (storeFeelGreat.GetContent('learnPage.faqs.isEnable', false)) {
                runInAction(() => {
                    data.titleGroup = []
                    data.items = []
                    data.questions = []
                })
                storeFeelGreat.GetContent('learnPage.faqs.items', []).map(item => {
                    runInAction(() => {
                        data.titleGroup.push(item.questionGroupName)
                        data.items.push(item)
                    })
                })
            }
            setLoading(false)
        }, 1000)
    }, [])

    return (
        <Stack
            sx={{
                boxSizing: 'border-box',
                padding: {
                    xs: '60px 0px'
                },
                paddingBottom: {
                    xs: '0px'
                },
                width: {
                    xs: '100%'
                },
                backgroundColor: '#ffffff',
                alignItems: 'center',
                margin: {
                    md: '100px auto'
                }
            }}>
            <Typography
                variant="h6"
                style={{ fontWeight: 600, marginBottom: '32px' }}
                textAlign={'center'}
                sx={{
                    fontSize: {
                        xs: '28px',
                        sm: '40px'
                    }
                }}>
                {StoreTranslate.UseLanguage(storeFeelGreat.GetContent('learnPage.faqs.title'))}
            </Typography>
            <Stack
                width={{
                    xs: '100%',
                    md: '100%'
                }}
                maxWidth={{
                    md: '970px'
                }}
                overflow={{
                    xs: 'scroll'
                }}
                boxSizing={'border-box'}>
                <Stack
                    sx={{
                        width: {
                            xs: '100%'
                        },
                        boxSizing: 'border-box'
                    }}>
                    <GroupButtonTab
                        sx={{
                            display: {
                                xs: 'inline-block',
                                sm: 'flex'
                            },
                            justifyContent: {
                                xs: 'space-between'
                            },
                            transform: {
                                xs: 'translateX(24px)',
                                md: 'translateX(0px)'
                            }
                        }}>
                        {data.titleGroup.map((item, key) => {
                            return (
                                <ButtonTabStyle
                                    key={key}
                                    variant="outlined"
                                    isselected={data.selectedGroup === key ? 'true' : 'false'}
                                    onClick={e => handleChange(key)}
                                    sx={{
                                        width: {
                                            xs: 'fit-content',
                                            sm: '220px'
                                        },
                                        marginRight: {
                                            xs: '16px',
                                            sm: '0'
                                        }
                                    }}>
                                    <Typography variant="caption" fontWeight={data.selectedGroup === key ? 600 : 400} fontSize={14}>
                                        {StoreTranslate.UseLanguage(item)}
                                    </Typography>
                                </ButtonTabStyle>
                            )
                        })}
                    </GroupButtonTab>
                </Stack>
            </Stack>
            <Stack>
                <ListComponentStyled
                    maxWidth={{
                        md: '970px'
                    }}
                    sx={{
                        padding: {
                            xs: '0 24px',
                            md: '0'
                        },
                        boxSizing: 'border-box'
                    }}>
                    {data.items.map((group, groupKey) => {
                        return group.questions.map((item, itemKey) => {
                            const isselected = state.groupKey === groupKey && state.itemKey === itemKey

                            return (
                                <AccordionStyled
                                    key={itemKey}
                                    expanded={isselected}
                                    style={{
                                        display: groupKey === data.selectedGroup ? 'block' : 'none',
                                        marginBottom: 16,
                                        padding: '0px 20px'
                                    }}
                                    onChange={() => {
                                        if (isselected) {
                                            setState({ groupKey: null, itemKey: null })
                                            return
                                        }
                                        setState({ groupKey, itemKey })
                                    }}
                                    isselected={isselected.toString()}
                                    TransitionProps={{ unmountOnExit: true }}>
                                    <AccordionSummaryStyled
                                        isshow={isselected.toString()}
                                        expandIcon={isselected ? <MinusIcon /> : <PlusIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header">
                                        <Typography
                                            variant="body3"
                                            fontWeight={600}
                                            lineHeight={'24px'}
                                            key={groupKey}
                                            style={{ listStyle: 'none', fontSize: '16px', fontFamily: 'Poppins' }}>
                                            {StoreTranslate.UseLanguage(item.question)}
                                        </Typography>
                                    </AccordionSummaryStyled>
                                    <AccordionDetailsStyled>
                                        <Typography
                                            variant="body3"
                                            lineHeight={'24px'}
                                            fontWeight={400}
                                            component={'div'}
                                            style={{ fontSize: '16px', fontFamily: 'Poppins' }}
                                            dangerouslySetInnerHTML={{ __html: StoreTranslate.UseLanguage(item.answer) }}
                                        />
                                    </AccordionDetailsStyled>
                                </AccordionStyled>
                            )
                        })
                    })}
                </ListComponentStyled>
            </Stack>
        </Stack>
    )
})

export default Faq
