import styled from '@emotion/styled'
import { Box, Stack, Modal, Typography, Fade } from '@mui/material'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import { T } from 'GlobalStores/Cores/Translator/useTranslate'
import StoreFeelGreat from 'GlobalStores/FeelGreat/StoreFeelGreat'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { ReactComponent as Cross } from './Assets/cross.svg'
import ImageHelper from 'Helpers/ImageHelper'
import { useSizeWindow } from 'Helpers/GlobalHelpers'
import { has } from 'lodash'

const MoneyBack = observer(props => {
    const [showMoneyBackModal, setShowMoneyBackModal] = useState(false)
    const moneyBackGuarantee = StoreFeelGreat.GetContent('moneyBackGuarantee')
    const isEng = StoreTranslate.IsEnglish() ? 'english' : 'native'
    const { height } = useSizeWindow()

    const handleCloseModal = () => {
        setShowMoneyBackModal(false)
    }

    return (
        <Stack mt="20px">
            {StoreFeelGreat.contents.moneyBack && (
                <Box textAlign={'center'}>
                    <Typography
                        variant="label"
                        fontWeight={400}
                        style={{
                            cursor: 'pointer',
                            textDecoration: 'underline'
                        }}
                        color="#153862"
                        onClick={() => setShowMoneyBackModal(true)}>
                        {StoreTranslate.UseLanguage(StoreFeelGreat.contents.moneyBack.titles)}
                    </Typography>
                </Box>
            )}
            <MoneyBackModal open={showMoneyBackModal} onClose={handleCloseModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Fade in={showMoneyBackModal}>
                    <Box className="MuiBox-root-wrapper">
                        <Cross
                            style={{
                                top: 15,
                                right: 15,
                                cursor: 'pointer',
                                position: 'absolute',
                                height: '20px'
                            }}
                            onClick={handleCloseModal}
                        />

                        {has(moneyBackGuarantee, 'titles') && (
                            <Box
                                sx={{
                                    overflowY: 'scroll',
                                    maxHeight: {
                                        xs: '100%',
                                        md: `${height - height * 0.2}px`
                                    }
                                }}>
                                <Typography variant="h6" component="h2">
                                    <ImageStyled src={moneyBackGuarantee.headerImage} />
                                </Typography>

                                {moneyBackGuarantee.titles[isEng].map((cotent, key) => {
                                    return <Typography variant="h4" fontWeight={500} dangerouslySetInnerHTML={{ __html: cotent }} key={key} />
                                })}

                                {moneyBackGuarantee.subTitles[isEng].map((cotent, key) => {
                                    return <Typography mt={'20px'} variant="h5" fontWeight={500} dangerouslySetInnerHTML={{ __html: cotent }} key={key} />
                                })}

                                {moneyBackGuarantee.paragraphs[isEng].map((cotent, key) => {
                                    return (
                                        <Typography
                                            key={key}
                                            mt={'10px'}
                                            fontSize="12px"
                                            variant="caption3"
                                            component={'p'}
                                            dangerouslySetInnerHTML={{ __html: cotent }}
                                        />
                                    )
                                })}

                                <Box
                                    sx={{
                                        margin: 0,
                                        padding: 0,
                                        marginTop: {
                                            xs: '20px',
                                            sm: '40px'
                                        },
                                        marginBottom: {
                                            xs: '20px',
                                            sm: '0px'
                                        },
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: {
                                            xs: 'center',
                                            sm: 'start'
                                        }
                                    }}>
                                    <Typography variant="link" fontWeight={600} onClick={handleCloseModal}>
                                        <T>CLOSE</T>
                                    </Typography>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Fade>
            </MoneyBackModal>
        </Stack>
    )
})

const ImageStyled = styled(ImageHelper)`
    width: 32px;
    /* width: 700px;
    height: auto;
    border-radius: 12px;
    margin-bottom: 20px;
    max-width: 100%;

    @media screen and (min-width: 1180px) {
        min-height: 200px;
    }

    .owl-carousel .owl-item img {
        @media screen and (max-width: 768px) {
            width: 147px !important;
            height: 70px;
        }
    } */
`

const MoneyBackModal = styled(Modal)`
    .MuiBox-root-wrapper {
        position: relative;
        box-sizing: border-box;
        margin: 5%;
        padding: 20px 20px 30px 20px;
        max-width: 600px;
        background-color: #fff;
        border-radius: 12px;
        :focus {
            outline: none;
        }
        @media screen and (max-width: 600px) {
            height: 95vh;
        }

        @media screen and (min-width: 600px) {
            margin: 5% auto auto auto;
        }
    }
`
export default MoneyBack
