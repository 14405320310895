import styled from '@emotion/styled'
import { Stack, ThemeProvider } from '@mui/material'
import { MainTemplate } from 'Components/Template/MainTemplate'
import { storeElement } from 'GlobalStores/StoreElement'
import { observer } from 'mobx-react-lite'
import HowToUse from './Sections/HowToUse'
import ThreeSimpleSteps from './Sections/ThreeSimpleSteps'
import VideoSection from './Sections/VideoSection'
import ProTips from './Sections/ProTips'
import { Fragment, useEffect, useState } from 'react'
import { IconSpinner } from 'Components/Elements/Spinner/IconSpinner'
import storeFeelGreat from 'GlobalStores/FeelGreat/StoreFeelGreat'
import Faq from './Sections/Faq'
import ReferSection from './Sections/ReferSection'
import ImageSection from './Sections/ImageSection'

const ContainerStyled = styled(Stack)`
    flex-direction: column;
`

const LearnPage = observer(() => {
    const [isReady, setIsReady] = useState(false)

    useEffect(() => {
        if (storeFeelGreat.GetContent('learnPage')) {
            setIsReady(true)
        }
    }, [])

    return (
        <ThemeProvider theme={MainTemplate}>
            <ContainerStyled
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center'
                }}>
                <Stack alignItems={'center'} width={'100%'}>
                    {isReady ? (
                        <Fragment>
                            {storeFeelGreat.GetContent('learnPage.videoSection.isEnable', false) && <VideoSection />}
                            {storeFeelGreat.GetContent('learnPage.imageSection.isEnable', false) && <ImageSection />}
                            {storeFeelGreat.GetContent('learnPage.howToUse.isEnable', false) && <HowToUse />}
                            {storeFeelGreat.GetContent('learnPage.threeSimpleSteps.isEnable', false) && <ThreeSimpleSteps />}
                            {storeFeelGreat.GetContent('learnPage.proTips.isEnable', false) && <ProTips />}
                            {storeFeelGreat.GetContent('learnPage.faqs.isEnable', false) && <Faq />}
                            {storeFeelGreat.GetContent('learnPage.referSection.isEnable', false) && <ReferSection />}
                        </Fragment>
                    ) : (
                        <Stack minHeight="100vh" justifyContent="center" alignItems="center">
                            <IconSpinner />
                        </Stack>
                    )}
                </Stack>
            </ContainerStyled>
        </ThemeProvider>
    )
})

export default LearnPage
