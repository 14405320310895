import { Box, Container, Grid, Stack, Skeleton, ThemeProvider, Typography, List, ListItemIcon, ListItemText, ListItem } from '@mui/material'
import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'
import { MainTemplate } from '../../../../Template/MainTemplate'
import CardWithPicture from '../../../../Layouts/Cards/CardWithPicture'
import Unimate from './products/unimate.jpeg'
import Balance from './products/balance.jpeg'
import { ReactComponent as CheckMark } from './svg/check-icon-1.svg'
import { ReactComponent as External } from './svg/external-page.svg'
import { useEffect, useState } from 'react'
import LandingBoxWrapper from 'Components/Elements/Boxes/LandingBoxWrapper'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import { isEmpty, isNothing } from 'Utils/Utils'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import { DefaultButton } from 'Components/Elements/Forms'
import { storeElement } from 'GlobalStores/StoreElement'
import { useNavigate } from 'react-router-dom'
const TheProducts = observer(props => {
    const translate = useTranslate()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const theProducts = props.data

    const contentDefault = {
        title: 'The Products',
        items: [
            {
                title: 'Unimate',
                image: {
                    alt: 'Unimate',
                    height: '380px',
                    width: '556px',
                    url: Unimate
                },
                description: 'Get-up-and-go with Unimate. This highly concentrated yerba mate drink contains a unique blend of plant compounds known to:',
                description2: 'Plus, it’s sugar free!',
                checkList: ['Improve mood', 'Boost energy', 'Heighten mental clarity', 'Suppress appetite'],
                learnMoreUrl: 'https://ushop-media.unicity.com/uploads/AUS/Unimate_Lemon_Ginger.pdf'
            },
            {
                title: 'Balance',
                image: {
                    alt: 'Balance',
                    height: '380px',
                    width: '556px',
                    url: Balance
                },
                description:
                    'Balance is the smart solution to balanced nutrition. This patented blend of fibers, nutrients, and plant compounds are specifically designed to:',
                checkList: [
                    'Slow carbohydrate absorption so you stay full longer',
                    'Promote proper digestion and cholesterol absorption',
                    'Provide 9 essential vitamins and minerals'
                ]
                // learnMoreUrl: ''
            }
        ]
    }

    useEffect(() => {
        if (theProducts) {
            setLoading(false)
        }
    }, [theProducts])

    return (
        <ThemeProvider theme={MainTemplate}>
            {!loading ? (
                <LandingBoxWrapper id="the_product">
                    <Box textAlign={'center'} mb="40px" mt={['JPN'].includes(storeCountry.Country3()) ? '40px' : '80px'}>
                        <Typography variant="h6">{StoreTranslate.UseLanguage(theProducts.title)}</Typography>
                    </Box>
                    {theProducts ? (
                        <TheProductsDetailStyled className="products--detail">
                            {theProducts.items.map((content, key) => {
                                return (
                                    <CardWithPicture
                                        key={key}
                                        image={content.image}
                                        style={{ background: 'transparent', padding: '0px', paddingBottom: 60 }}
                                        cardContentStyle={{ padding: 0 }}
                                        footer={
                                            StoreTranslate.UseLanguage(content.learnMoreLink) ? (
                                                <>
                                                    <Typography
                                                        variant="link2"
                                                        component={'a'}
                                                        href={StoreTranslate.UseLanguage(content.learnMoreLink)}
                                                        target={content.learnMoreOpenTarget}
                                                        mt="20px"
                                                        mr="5px">
                                                        {StoreTranslate.UseLanguage(content.learnMoreText)}
                                                    </Typography>
                                                    <Box mt="20px" display={'flex'} alignItems={'center'}>
                                                        <External />
                                                    </Box>
                                                </>
                                            ) : (
                                                <></>
                                            )
                                        }>
                                        <Typography variant="body1" mt="44px" mb="30px">
                                            {StoreTranslate.UseLanguage(content.title)}
                                        </Typography>
                                        {content.description && (
                                            <Typography
                                                variant="body2"
                                                fontSize={'18px'}
                                                mt="20px"
                                                mb="30px"
                                                dangerouslySetInnerHTML={{
                                                    __html: StoreTranslate.UseLanguage(content.description)
                                                }}
                                            />
                                        )}

                                        {StoreTranslate.UseLanguage(content.bullets)?.length > 0 ? (
                                            <List disablePadding sx={{ paddingTop: '7px' }}>
                                                {StoreTranslate.UseLanguage(content.bullets).map((bullet, key) => {
                                                    return (
                                                        <BulletContentListItem disablePadding alignItems="flex-start" key={key}>
                                                            <ListItemIcon>
                                                                {!isNothing(content.bulletSymbolUrl) ? (
                                                                    <img src={content.bulletSymbolUrl} alt="-" />
                                                                ) : (
                                                                    <CheckMark />
                                                                )}
                                                            </ListItemIcon>
                                                            <ListItemText primary={bullet} />
                                                        </BulletContentListItem>
                                                    )
                                                })}
                                            </List>
                                        ) : null}

                                        {content.lastParagraph && !isEmpty(StoreTranslate.UseLanguage(content.lastParagraph)) ? (
                                            <Typography
                                                variant="body2"
                                                fontSize={'18px'}
                                                mt="60px"
                                                mb="30px"
                                                dangerouslySetInnerHTML={{
                                                    __html: StoreTranslate.UseLanguage(content.lastParagraph)
                                                }}
                                            />
                                        ) : null}
                                    </CardWithPicture>
                                )
                            })}
                        </TheProductsDetailStyled>
                    ) : (
                        <TheProductsDetailStyled className="products--detail">
                            {contentDefault.items.map((content, key) => {
                                return (
                                    <CardWithPicture
                                        key={key}
                                        image={content.image}
                                        style={{ background: 'transparent', padding: '0px' }}
                                        footer={
                                            <>
                                                <Typography variant="link2" component={'a'} href={content.learnMoreUrl} target="_blank" mt="20px" mr="5px">
                                                    <T>the_product_learn_more</T>
                                                </Typography>
                                                <Box mt="20px" display={'flex'} alignItems={'center'}>
                                                    <External />
                                                </Box>
                                            </>
                                        }>
                                        <Typography variant="body1" mt="44px" mb="30px">
                                            {content.title}
                                        </Typography>
                                        {content.description && (
                                            <Typography variant="body2" fontSize={'18px'} mt="20px" mb="30px">
                                                {content.description}
                                            </Typography>
                                        )}
                                        {content.checkList.length > 0 && (
                                            <ListWithIconsStyled>
                                                {content.checkList.map((item, itemKey) => {
                                                    return (
                                                        <ListWithIconStyled className="list-item" key={itemKey}>
                                                            <Box pt="8px">
                                                                <CheckMark width={30} style={{ width: 18, marginRight: 15 }} />
                                                            </Box>
                                                            <Typography variant="body2" key={itemKey} style={{ listStyle: 'none' }}>
                                                                {item}
                                                            </Typography>
                                                        </ListWithIconStyled>
                                                    )
                                                })}
                                            </ListWithIconsStyled>
                                        )}
                                        {content.description2 && <Typography variant="body2">{content.description2}</Typography>}
                                        {content.benefit && (
                                            <Stack mb="30px" mt="30px">
                                                <Typography variant="link2" mb="20px" fontWeight="400">
                                                    <T>benefits</T>
                                                </Typography>
                                                <ListWithIconsStyled>
                                                    {content.benefit.map((item, itemKey) => {
                                                        return (
                                                            <ListWithIconStyled className="list-item" key={itemKey}>
                                                                <Box pt="8px">
                                                                    <CheckMark width={30} style={{ width: 18, marginRight: 15 }} />
                                                                </Box>
                                                                <Typography variant="body2" key={itemKey} style={{ listStyle: 'none' }}>
                                                                    {item}
                                                                </Typography>
                                                            </ListWithIconStyled>
                                                        )
                                                    })}
                                                </ListWithIconsStyled>
                                            </Stack>
                                        )}
                                    </CardWithPicture>
                                )
                            })}
                        </TheProductsDetailStyled>
                    )}
                </LandingBoxWrapper>
            ) : (
                <Container>
                    <Stack mb="60px">
                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} mb="40px" mt="80px">
                            <Skeleton variant="rounded" width={'40%'} height={40} />
                        </Box>
                        <TheProductsDetailStyled className="products--detail">
                            {Array.from(Array(2).keys()).map(num => {
                                return (
                                    <Grid item sm={6} key={'skel' + num}>
                                        <Skeleton variant="rounded" width={'100%'} height={'360px'} />
                                        <Skeleton variant="rounded" width={'30%'} height={40} sx={{ marginTop: '54px' }} />
                                        <Skeleton variant="rounded" width={'100%'} height={20} sx={{ marginY: '10px' }} />
                                        <Skeleton variant="rounded" width={'100%'} height={20} sx={{ marginY: '10px' }} />
                                        <Skeleton variant="rounded" width={'100%'} height={20} sx={{ marginY: '10px' }} />
                                        <Skeleton variant="rounded" width={'40%'} height={10} sx={{ marginTop: '20px' }} />
                                        <Skeleton variant="rounded" width={'40%'} height={10} sx={{ marginTop: '5px' }} />
                                        <Skeleton variant="rounded" width={'40%'} height={10} sx={{ marginTop: '5px' }} />
                                        <Skeleton variant="rounded" width={'40%'} height={10} sx={{ marginTop: '5px' }} />
                                        <Skeleton variant="rounded" width={'40%'} height={30} sx={{ marginTop: '20px' }} />
                                    </Grid>
                                )
                            })}
                        </TheProductsDetailStyled>
                    </Stack>
                </Container>
            )}
        </ThemeProvider>
    )
})

const TheProductsDetailStyled = styled(Stack)`
    &.products--detail {
        @media screen and (min-width: 768px) {
            width: 80%;
            align-self: center;
        }

        @media screen and (min-width: 1180px) {
            display: grid;
            grid-template-columns: repeat(2, 468px);
            grid-gap: 50px;
            column-gap: 50px;
            grid-column-gap: 50px;
            justify-content: center;
            max-width: 1136px;
        }

        @media screen and (min-width: 1366px) {
            display: grid;
            grid-template-columns: repeat(2, 568px);
            grid-gap: 50px;
            column-gap: 50px;
            grid-column-gap: 50px;
            justify-content: center;
        }
    }
`

const ListWithIconsStyled = styled(Stack)`
    margin-bottom: 40px;
`
const ListWithIconStyled = styled(Stack)`
    &.list-item {
        display: grid;
        grid-template-columns: 30px auto;
        align-items: flex-start;
    }
`

const BulletContentListItem = styled(ListItem)`
    margin-top: 18px;
    margin-bottom: 18px;
    img,
    svg {
        width: 15px;
        height: 15px;
    }

    & > .MuiListItemIcon-root {
        min-width: 15px;
        height: 24px;
        padding-right: 6px;
        align-items: center;
        margin: 0;

        @media screen and (min-width: 431px) {
            height: 27px;
        }
    }
    & > .MuiListItemText-root {
        margin: 0;
    }

    span.MuiTypography-root {
        font-size: 16px !important;
        font-weight: 400 !important;
        @media screen and (min-width: 431px) {
            font-size: 18px !important;
        }
    }
`

export default TheProducts
