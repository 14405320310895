import styled from '@emotion/styled'
import { Stack, Typography } from '@mui/material'
import { useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { observer } from 'mobx-react-lite'
import storeFeelGreat from 'GlobalStores/FeelGreat/StoreFeelGreat'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'

const ContainerMobile = styled(Stack)`
    flex-direction: column;
    justify-content: center;
    margin-top: 40px;
    width: 300px;
    img {
        max-width: 200px;
    }
`

const ContainerMobileItem = styled(Stack)`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
`

const ContainerMobileItemFont = styled(Stack)`
    margin-top: 30px;
    font-size: 18px;
    font-weight: 400;
    font-family: Inter, Poppins, Prompt;
    text-align: center;
    display: block;
    strong {
        font-weight: 600;
    }
    margin: 0;
    p {
        color: #0b3b61;
        line-height: 27px;
    }
`

const ContainerDesktop = styled(Stack)`
    width: 985px;
`
const ContainerDesktopItem = styled(Stack)`
    width: 200px;
`
const ContainerDesktopItems = styled(Stack)`
    flex-direction: row;
    width: 985px;
    justify-content: space-between;
`

const HowToUse = observer(() => {
    const translate = useTranslate()

    return (
        <Stack
            bgcolor={'#f1f6fb'}
            minHeight={'100px'}
            width={'100%'}
            sx={{
                padding: {
                    xs: '60px 0',
                    sm: '80px 0',
                    md: '120px 0'
                },
                alignItems: 'center'
            }}>
            <ContainerDesktop
                sx={{
                    display: {
                        xs: 'none',
                        sm: 'none',
                        md: 'flex',
                        lg: 'flex'
                    }
                }}>
                <Typography
                    variant="h2"
                    sx={{
                        fontSize: {
                            xs: '28px',
                            sm: '28px',
                            md: '40px',
                            lg: '40px'
                        }
                    }}>
                    {translate('how_to_use')}
                </Typography>
                <img src={storeFeelGreat.GetContent('learnPage.howToUse.desktopVersion.imageUrl', '')} alt="" style={{ marginTop: '60px' }} />
                <ContainerDesktopItems>
                    {storeFeelGreat.GetContent('learnPage.howToUse.desktopVersion.textItems', []).map((item, index) => {
                        return (
                            <ContainerDesktopItem key={index}>
                                <ContainerMobileItemFont dangerouslySetInnerHTML={{ __html: StoreTranslate.UseLanguage(item) }}></ContainerMobileItemFont>
                            </ContainerDesktopItem>
                        )
                    })}
                </ContainerDesktopItems>
            </ContainerDesktop>
            <ContainerMobile
                sx={{
                    display: {
                        xs: 'flex',
                        sm: 'flex',
                        md: 'none',
                        lg: 'none'
                    }
                }}>
                <Typography
                    variant="h2"
                    textAlign={'center'}
                    sx={{
                        fontSize: {
                            xs: '28px',
                            sm: '28px',
                            md: '28px',
                            lg: '40px'
                        },
                        marginBottom: '60px'
                    }}>
                    {translate('how_to_use')}
                </Typography>
                {storeFeelGreat.GetContent('learnPage.howToUse.mobileVersion.items', []).map((item, index) => {
                    return (
                        <ContainerMobileItem key={index}>
                            <img src={item.imageUrl} alt="Learn" />
                            <ContainerMobileItemFont dangerouslySetInnerHTML={{ __html: StoreTranslate.UseLanguage(item.textItem) }}></ContainerMobileItemFont>
                        </ContainerMobileItem>
                    )
                })}
            </ContainerMobile>
        </Stack>
    )
})

export default HowToUse
