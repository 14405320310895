export const Paths = [
    'products',
    'reset-password',
    'reset-password-success',
    'reset-password-error',
    'new-password',
    'new-password-success',
    'new-password-error',
    'sign-up',
    'sign-up-response',
    'payment-response-success',
    'payment-response',
    'test',
    'order-history',
    'subscriptions',
    'personal-details',
    'invite',
    'order-history-mobile',
    'order-history-details',
    'invite-mobile',
    'checkout',
    'login',
    'user-account',
]
