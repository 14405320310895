import { observer, useLocalObservable } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import mobiscroll from '@mobiscroll/react'
import '@mobiscroll/react/dist/css/mobiscroll.react.scss'
import './ModalManySelect.css'
import { reaction } from 'mobx'
import { ReactComponent as DropdownIcon } from '../Assets/dropdown.svg'
import { storeElement } from 'GlobalStores/StoreElement'
import { Stack } from '@mui/material'
import { lowerCase } from 'lodash'
import { DefaultLabelTypo } from '../InputsStyled'
import storeCheckout from 'GlobalStores/Checkout/StoreCheckout'

const ModalManySelect = observer(({ item, label }) => {
    const [options, setOptions] = useState([])

    const localObserver = useLocalObservable(() => ({
        listForRender: [],
        value: ''
    }))

    useEffect(() => {
        let data = []
        if (storeCheckout.shippingMethod?.api_value === 'sameday') {
            data = storeCheckout
                .CheckOutInstance()
                .Instance()
                .AddressOptionListSameDay(item.key)
                .map(i => i.value)
        } else {
            data = storeCheckout
                .CheckOutInstance()
                .Instance()
                .AddressOptionList(item.key)
                .map(i => i.value)
        }
        setOptions(data)
    }, [storeCheckout.shippingMethod])

    const renderProvinces = async () => {
        try {
            localObserver.listForRender = []

            let list = []
            list = options.map(province => {
                return {
                    value: province,
                    text: province
                }
            })

            localObserver.listForRender = list
            setPropValueToState(localObserver.value)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        renderProvinces()
    }, [options])

    const setPropValueToState = propValue => {
        if (propValue) {
            let val = lowerCase(propValue)
            localObserver.listForRender.map(l => {
                if (lowerCase(l.text).includes(val)) {
                    localObserver.value = l.value
                }
                return l
            })
        }
    }

    useEffect(() => {
        const disposer = reaction(
            () => StoreTranslate.CurrentLanguage(),
            (n, p) => {
                if (n !== p) {
                    renderProvinces()
                }
            }
        )
        return () => {
            disposer()
        }
    }, [])

    useEffect(() => {
        if (item.value) {
            if (localObserver.listForRender.length > 0) {
                setPropValueToState(item.value)
            } else if (localObserver.listForRender.length === 0) {
                renderProvinces()
                setPropValueToState(item.value)
            }
        } else {
            if (localObserver.listForRender.length === 0) {
                renderProvinces()
            }
        }
    }, [item.value, localObserver.listForRender.length])

    const onSelected = (event, inst) => {
        storeCheckout.SetCheckoutFormValue(item.key, event.valueText)
    }

    return (
        <Stack>
            <div
                style={{
                    display: 'flex',
                    justifyContent: storeElement.dynamicWidth > 768 && 'space-between',
                    marginBottom: '8px',
                    paddingLeft: '16px'
                }}>
                <label>
                    <DefaultLabelTypo variant="label" style={{ color: 'rgba(21, 56, 98, 0.5)' }}>
                        {label}
                    </DefaultLabelTypo>
                </label>
            </div>

            {/* <label style={{ fontWeight: 400, fontSize: "18px" }}>{props.label}</label> */}
            <Stack className="mobi-custom-input">
                <mobiscroll.Select
                    className={item.key || 'province'}
                    key={item.key || 'province'}
                    display="center"
                    touchUi={false}
                    filter={true}
                    height={40}
                    maxWidth={400}
                    data={localObserver.listForRender}
                    onSet={(e, inst) => onSelected(e, inst)}
                    value={item.value}
                    filterPlaceholderText=""
                    group={{
                        groupWheel: false,
                        header: false
                    }}>
                    <mobiscroll.Input>
                        <Stack position={'absolute'} right={'15px'} top={'45%'}>
                            <DropdownIcon style={{ cursor: 'pointer', color: 'rgba(153, 188, 223, 0.25)' }} />
                        </Stack>
                    </mobiscroll.Input>
                </mobiscroll.Select>
            </Stack>
        </Stack>
    )
})

export default ModalManySelect
