import { Box, Container, Stack, styled, ThemeProvider, Typography } from '@mui/material'
import { useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import StoreFeelGreat from 'GlobalStores/FeelGreat/StoreFeelGreat'
import { storeElement } from 'GlobalStores/StoreElement'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { MainTemplate } from '../../../../Template/MainTemplate'
import DefaultButton from '../../../Forms/DefaultButton'

import bannerMobile from './assets/banner-mobile.jpg'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import { useEffect, useState } from 'react'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import StoreCheckout from 'GlobalStores/Checkout/StoreCheckout'
import storeAuth from 'GlobalStores/User/StoreAuth'
import ButtonWithPopup from 'Components/Elements/Forms/ButtonWithPopup'
import storeReferral from 'GlobalStores/Referral/StoreReferral'

const Banner = observer(() => {
    const navigate = useNavigate()
    const translate = useTranslate() 

    const [action, setAction] = useState({
        actionTitle: 'buy_now',
        link: '/products',
        isLogin: false
    })

    useEffect(() => {
        getAction()
    }, [storeReferral.HasReferral()])

    const getAction = () => {
        if (['JPN'].includes(storeCountry.Country3())) {
            setAction({
                ...action,
                actionTitle: 'buy_now',
                link: '/products',
                // isLogin: true
            })
        }

        if (['THA'].includes(storeCountry.Country3())) {
            if (!storeAuth.IsAuthorized() && !storeReferral.HasReferral()) {
                setAction({
                    ...action,
                    link: '/login',
                })
            } else {
                setAction({
                    ...action,
                    link: '/products',
                })
            }
        }
    }

    const md = storeElement.dynamicWidth < 834

    const isShowButton = () => {
        // if (['JPN'].includes(storeCountry.Country3())) {
        //     return !storeAuth.IsAuthorized()
        // }
        return true
    }

    return (
        <ThemeProvider theme={MainTemplate}>
            <ContainerStyled maxWidth="xl">
                {StoreFeelGreat.GetIsInit() ? (
                    !md ? (
                        <StackStyled
                            backgroundimage={StoreFeelGreat.GetContent('mainBanner').imageDesktop}
                            display="flex"
                            justifyContent={'center'}
                            paddingLeft={'135px'}>
                            <Box width="40%">
                                <Typography
                                    width="379px"
                                    variant="h2"
                                    dangerouslySetInnerHTML={{
                                        __html: StoreTranslate.UseLanguage(StoreFeelGreat.GetContent('mainBanner').title)
                                    }}
                                />

                                <TypographySubTitleBannerStyled
                                    component={'div'}
                                    variant="h4"
                                    fontWeight={400}
                                    dangerouslySetInnerHTML={{
                                        __html: StoreTranslate.UseLanguage(StoreFeelGreat.GetContent('mainBanner').description)
                                    }}
                                    mt="0px"
                                />
                                {isShowButton() && (
                                    <>
                                        {action.isLogin ? (
                                            <ButtonWithPopup
                                                backgroundcolor="#003764"
                                                titleText={translate(action.actionTitle)}
                                                callback={() => StoreCheckout.SetIsLoginOnly(true)}
                                            />
                                        ) : (
                                            <DefaultButton
                                                titleText={translate(action.actionTitle)}
                                                fullWidth={true}
                                                width="auto"
                                                sx={{
                                                    padding: '0 42px'
                                                }}
                                                backgroundcolor="#003764"
                                                onClick={() => {
                                                    let navUrl = action.link
                                                    navigate(navUrl)
                                                }}
                                            />
                                        )}
                                    </>
                                )}
                            </Box>
                        </StackStyled>
                    ) : (
                        <StackStyled backgroundimage={StoreFeelGreat.GetContent('mainBanner').imageMobile} display="flex" justifyContent={'start'}>
                            <BoxStyled>
                                <Typography
                                    variant="h2"
                                    className="header-banner"
                                    dangerouslySetInnerHTML={{
                                        __html: StoreTranslate.UseLanguage(StoreFeelGreat.GetContent('mainBanner').title)
                                    }}
                                />

                                <TypographySubTitleBannerStyled
                                    component={'div'}
                                    variant="h4"
                                    fontWeight={400}
                                    dangerouslySetInnerHTML={{
                                        __html: StoreTranslate.UseLanguage(StoreFeelGreat.GetContent('mainBanner').description)
                                    }}
                                    mt="0px"
                                />
                                {isShowButton() && (
                                    <>
                                        {action.isLogin ? (
                                            <ButtonWithPopup backgroundcolor="#003764" titleText={translate(action.actionTitle)} />
                                        ) : (
                                            <DefaultButton
                                                titleText={translate(action.actionTitle)}
                                                fullWidth={true}
                                                width="auto"
                                                sx={{
                                                    padding: '0 42px'
                                                }}
                                                backgroundcolor="#003764"
                                                onClick={() => {
                                                    let navUrl = action.link
                                                    navigate(navUrl)
                                                }}
                                            />
                                        )}
                                    </>
                                )}
                            </BoxStyled>
                        </StackStyled>
                    )
                ) : null}
            </ContainerStyled>
        </ThemeProvider>
    )
})

const StackStyled = styled(Stack)`
    background-image: ${({ backgroundimage }) => `url(${backgroundimage})`};
    background-size: cover;
    background-position: center;

    @media screen and (min-width: 1600px) {
        // height: 708px;
        height: 542px;
    }

    @media screen and (max-width: 1440px) {
        height: 542px;
        background-position: center;
    }

    @media screen and (max-width: 824px) {
        width: 100%;
        height: 85vh;
        background-repeat: no-repeat;
        background-position: bottom center;
    }

    @media screen and (max-width: 430px) {
        width: 100%;
        height: 70vh;
        background-repeat: no-repeat;
        background-position: bottom center;
    }

    @media screen and (min-width: 1024px) {
        height: 542px;
    }
`

const BoxStyled = styled(Box)`
    @media screen and (max-width: 824px) {
        padding-top: 50px;
        padding-left: 100px;

        .header-banner {
            width: 379px;
        }
    }

    @media screen and (max-width: 430px) {
        padding-top: 40px;
        padding-left: 30px;

        .header-banner {
            width: 250px;
        }
    }
`

const ContainerStyled = styled(Container)`
    @media screen and (min-width: 600px) {
        padding-left: 0;
        padding-right: 0;
    }

    @media screen and (max-width: 430px) {
        padding-left: 0;
        padding-right: 0;
    }
`

const TypographySubTitleBannerStyled = styled(Typography)`
    margin: 20px 0 30px 0;
    line-height: 28px;

    p {
        margin-block-start: 0px;
        margin-block-end: 0px;
    }

    @media screen and (min-width: 730px) {
        margin: 30px 0;
    }
`

export default Banner
