import { Stack, ThemeProvider } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import AppComponent from 'Components/Elements/AppComponent/AppComponent'
import { MainTemplate } from 'Components/Elements/AppComponent/Template/MainTemplate'
import storeFeelGreat from 'GlobalStores/FeelGreat/StoreFeelGreat'
import { toJS } from 'mobx'

const AppPage = observer(() => {
    const navigate = useNavigate()
    const country = storeCountry.Country2()

    const appData = toJS(storeFeelGreat.GetContent('appPage'))

    const countryIsOpenPage = ['TH', 'JP']

    useEffect(() => {
        if (!countryIsOpenPage.includes(country) && !appData.isEnable) {
            navigate('/')
        }
    }, [country])

    return (
        <ThemeProvider theme={MainTemplate}>
            <Stack>
                <AppComponent />
            </Stack>
        </ThemeProvider>
    )
})

export default AppPage
