import styled from '@emotion/styled'
import { Stack, Typography } from '@mui/material'
import { DefaultButton } from 'Components/Elements/Forms'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import { useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import storeFeelGreat from 'GlobalStores/FeelGreat/StoreFeelGreat'

const ContainerRefer = styled(Stack)`
    padding: 60px 30px;
    background-color: #f7f2ef;
    width: 100%;
    box-sizing: border-box;
`

const ReferSection = () => {
    const translate = useTranslate()
    return (
        <ContainerRefer
            sx={{
                padding: {
                    md: '120px 0',
                    lg: '120px 0'
                },
                alignItems: 'center'
            }}>
            <Stack
                sx={{
                    maxWidth: {
                        md: '770px',
                        lg: '770px'
                    }
                }}>
                <Typography
                    variant="h6"
                    style={{ fontWeight: 600 }}
                    textAlign={{
                        xs: 'center',
                        md: 'left',
                        lg: 'left'
                    }}
                    fontSize={{
                        xs: '28px',
                        sm: '28px',
                        md: '40px'
                    }}
                    marginBottom={{
                        xs: '32px',
                        sm: '32px',
                        md: '40px'
                    }}>
                    {StoreTranslate.UseLanguage(storeFeelGreat.GetContent('learnPage.referSection.title'))}
                </Typography>
                <Stack
                    borderRadius={'8px'}
                    overflow={'hidden'}
                    sx={{
                        flexDirection: {
                            md: 'row',
                            lg: 'row'
                        }
                    }}>
                    <Stack
                        sx={{
                            img: {
                                aspectRatio: {
                                    xs: '315/200',
                                    sm: '315/200',
                                    md: '1'
                                },
                                width: {
                                    xs: '100%',
                                    sm: '100%',
                                    md: '385px'
                                }
                            }
                        }}>
                        <img src={storeFeelGreat.GetContent('learnPage.referSection.imageUrl')} alt="Refer" style={{ objectFit: 'cover' }} />
                    </Stack>
                    <Stack
                        sx={{
                            padding: {
                                xs: '30px'
                            },
                            backgroundColor: '#ffffff',
                            justifyContent: 'center'
                        }}>
                        <Typography
                            variant="body2"
                            style={{
                                fontWeight: 600,
                                fontFamily: 'Poppins'
                            }}
                            sx={{
                                fontSize: {
                                    xs: '20px',
                                    sm: '20px',
                                    md: '24px'
                                }
                            }}>
                            {StoreTranslate.UseLanguage(storeFeelGreat.GetContent('learnPage.referSection.description_1'))}
                        </Typography>
                        <Typography
                            variant="body3"
                            style={{ fontFamily: 'Poppins' }}
                            mt="8px"
                            sx={{
                                fontSize: {
                                    xs: '14px',
                                    sm: '14px',
                                    md: '18px'
                                }
                            }}>
                            {StoreTranslate.UseLanguage(storeFeelGreat.GetContent('learnPage.referSection.description_2'))}
                        </Typography>
                        <DefaultButton
                            variant="contained"
                            color="primary"
                            style={{ marginTop: '24px', backgroundColor: '#0B3B61' }}
                            titleText={translate('login_now')}
                            sx={{
                                width: {
                                    xs: '100%',
                                    sm: '100%',
                                    md: 'fit-content'
                                },
                                padding: '0px 30px'
                            }}
                        />
                    </Stack>
                </Stack>
            </Stack>
        </ContainerRefer>
    )
}

export default ReferSection
