import StoreAuth from 'GlobalStores/User/StoreAuth'
import { makeAutoObservable, runInAction } from 'mobx'

import { get } from 'lodash'
import { ObjectHelpers } from 'Helpers/ObjectHelpers'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import storeAuth from 'GlobalStores/User/StoreAuth'
import { FeelGreatAPI } from 'Services/FeelGreat/APIs'

class StoreFeelGreat {
    province = []

    referral = {
        id: '',
        humanName: {},
        shortStatus: ''
    }

    // ตัวเก่า
    // useReferral = false
    // checkReferralId = null
    // referralId = ''
    // referralName = ''
    // stateReferrenceId = null

    forms = {
        createYourAccount: null,
        shippingAddress: null
    }

    defaultValues = {
        flavor: 'flavor_0',
        purchaseOption: 'subscribe'
    }

    orderSummary = {
        isShowReferredBy: {
            onetime: false,
            subscription: false
        },
        isShowSubscriptionMessage: {
            onetime: false,
            subscription: false
        },
        isShowMemberDiscount: {
            onetime: false,
            subscription: false
        },
        isShowSubscriptionDiscount: {
            onetime: false,
            subscription: false
        },
        referredBy: {
            native: '',
            english: ''
        },
        subscriptionMessage: {
            native: '',
            english: ''
        }
    }

    contents = {
        appPage: null,
        learnPage: null,
        consent: null,
        description: null,
        expertsSay: null,
        mainBanner: null,
        howItWorks: null,
        ourApproach: null,
        questions: null,
        realResults: null,
        secondBanner: null,
        thePractice: null,
        theProducts: null,
        quickLinks: null,
        disclaimer: null,
        privacyPolicy: null,
        termOfUse: null,
        termAndConditions: null,
        social: null,
        memberPriceUnlockReadMore: null,
        moneyBackGuarantee: null,
        moneyBack: null,
        contact: null,
        guilde: null,
        guildeButton: null,
        referralLinks: null,
        referralLinksSorted: [],
        freeItems: null,
        termAndConditions: null,
        socialStoriesGuidelines: null
    }

    imagesSlider = []
    image = ''

    purchaseOptions = []

    isInit = false

    referralCode = ''

    loadingReferCode = false

    productCredit = null

    subscriptionConfigData = null

    isAcceptPdpa = false
    updatePdpaLoading = false

    configSettings = {
        isAllowChangeDisplayName: false,
        isAllowChangeEmail: false,
        isAllowChangePDPA: false,
        isAllowChangePassword: false,
        isAllowChangeProfilePhoto: false
    }

    configSettingsLoadded = false

    Init(data) {
        Object.keys(data).map(key => {
            runInAction(() => {
                if (this.forms[key] !== undefined) {
                    this.forms[key] = data[key]
                } else if (this.contents[key] !== undefined) {
                    this.contents[key] = data[key]
                } else if (key === 'images') {
                    this.imagesSlider = data[key]
                } else if (this[key] !== undefined) {
                    this[key] = data[key]
                }
            })

            return true
        })

        runInAction(() => {
            this.isInit = true
        })
    }

    GetCheckoutForms() {
        return this.forms
    }

    GetOrderSummaryConditions() {
        return this.orderSummary
    }

    GetImagesSlider() {
        return this.imagesSlider
    }

    GetIsInit() {
        return this.isInit
    }

    GetContent(section) {
        return get(this.contents, section)
    }

    GetPurchaseOptions() {
        return this.purchaseOptions
    }

    GetImage() {
        return this.image
    }

    AddOnceItemToImage(img) {
        runInAction(() => {
            this.image = img
        })
    }

    UsePrice() {
        if (StoreAuth.GetId()) {
            return 'after'
        } else if (this.referral.id.length > 0) {
            return 'after'
        } else {
            return 'retail'
        }
    }

    SetReferralData(userRef, status) {
        this.referral.id = userRef.unicity
        this.referral.humanName = userRef.humanName
        this.referral.shortStatus = status
    }

    GetReferral() {
        return this.referral
    }

    HasReferral() {
        return this.referral.id && !ObjectHelpers.isEmpty(this.referral.humanName) ? this.referral.id : false
    }

    IsAllowProductCredit() {
        const allowedCountriesECredit = ['SG']
        const isCountryAllowed = allowedCountriesECredit.includes(storeCountry.Country2())
        return get(this.productCredit, 'isEnable') || isCountryAllowed
    }

    constructor() {
        makeAutoObservable(this)
    }

    async GetReferralCode(token) {
        try {
            this.loadingReferCode = true
            let response = await FeelGreatAPI.getReferralCodes(token)
            this.referralCode = get(response, 'data.items[0].referralCode', '')
            this.loadingReferCode = false
        } catch (error) {
            console.log('getReferralCode error => ', error)
            this.loadingReferCode = false
        }
    }

    GetIsAcceptPdpa() {
        return this.isAcceptPdpa
    }

    SetIsAcceptPdpa(value) {
        this.isAcceptPdpa = value
    }

    SetUpdatePdpaLoading(value) {
        this.updatePdpaLoading = value
    }

    UpdatePdpaLoading() {
        return this.updatePdpaLoading
    }

    SetSubscriptionConfigData(subscriptionConfigData) {
        if (subscriptionConfigData) {
            this.subscriptionConfigData = subscriptionConfigData
        }
    }

    GetSubscriptionConfigData(key) {
        if (key) {
            return get(this.subscriptionConfigData, key)
        } else {
            return this.subscriptionConfigData
        }
    }

    async UpdatePDPAAllowContact(value, overrideData = null) {
        try {
            const data = {
                ba_id: storeAuth.GetId(),
                ba_status: storeAuth.userStatus,
                is_allow_contact_by_unicity_or_sponsor: value
            }

            await FeelGreatAPI.setPdpaStatus(overrideData || data)
        } catch (e) {
            console.error(e)
        }
    }

    setConfigSettings(data) {
        this.configSettings = data
    }

    getConfigSettings(key) {
        return get(this.configSettings, key, false)
    }
}

const storeFeelGreat = new StoreFeelGreat()
export default storeFeelGreat
