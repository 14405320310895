import { ServerEnv } from 'Configs/ServerEnv'
import StoreCountry from 'GlobalStores/Cores/County/StoreCountry'
import storeAuth from 'GlobalStores/User/StoreAuth'
import storeUser from 'GlobalStores/User/StoreUser'
import HttpHelper from 'Services/HttpHelper'

export const GetFeelGreatProduct = async userStatus => {
    let options = {}
    if (userStatus) {
        options.params = {
            baStatus: userStatus
        }
        if (['MAR', 'TUR'].includes(StoreCountry.Country3())) {
            options.params.ordercalC = 1
            options.params.baId = storeAuth.GetId()
        }
        console.log('options', options, storeAuth.GetId(), storeUser.GetBaStatus())
    }
    else if (StoreCountry.Country3() === 'JPN' && Boolean(userStatus) === false) {
        // JPN Default Case When
        options.params = {
            baStatus: 'C'
        }
    }

    // const qs = new URLSearchParams(window.location.search)
    // if (qs.get('env') === 'dev' || /localhost/.test(window.location.href) || /fg-dev/.test(window.location.href)) {
    //     severEnv = ServerEnv.MemberCalls2Dev()
    // }
    const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/products-v2/feelGreat/v3/publish/${StoreCountry.Country3()}`

    return await HttpHelper.Get({ url, config: options })
}
