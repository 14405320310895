import axios from 'axios'
import { ServerEnv } from 'Configs/ServerEnv'
import StorePaymentStep from 'GlobalStores/Cores/Elements/StorePaymentStep'
import { ObjectHelpers } from 'Helpers/ObjectHelpers'
import { makeAutoObservable, runInAction } from 'mobx'
import { FeelGreatAPI } from 'Services/FeelGreat/APIs'
import Payment from '../Payment'
import { PaymentAPIs } from '../PaymentServices/PaymentAPI'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import storeCredit from 'GlobalStores/StoreCredit'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import PHLProvinceList from 'Configs/Address/Philippines/PHLProvinceList.json'
import PHLProvinceListSameDay from 'Configs/Address/Philippines/PHLProvinceList_sameDay.json'
import { get, isEmpty } from 'lodash'
import storeFormat from 'GlobalStores/Format/StoreFormat'
import storeFreightCharge from '../StoreFreightCharge'

class Philippines {
    autoAddressDisplayKeys = ['street', 'locality', 'postal_code']
    autoAddressKeyToForm = {
        street: 'address',
        locality: 'province',
        administrative_area: 'state',
        postal_code: 'zip_code'
    }

    uuid = ''

    paymentMethod = ''

    toFormat = {
        enroll: {
            uuid: this.GetUuid(),
            fullName: '',
            address: '',
            city: '',
            state: '',
            zip: '',
            email: '',
            phone: '',
            password: ''
            // password1: '',
            // password2: ''
        },
        enrollWithReferral: {
            uuid: this.GetUuid(),
            fullName: '',
            address: '',
            city: '',
            state: '',
            zip: '',
            email: '',
            phone: '',
            password1: '',
            password2: ''
        },
        shop: {
            uuid: this.GetUuid(),
            /*  fullname: '',
            address: '',
            city: '',
            state: '', */
            zip: '',
            email: ''
        }
    }

    formShop = {
        fullName: '',
        email: '',
        phone: '',
        street_address: '',
        city: '',
        state: '',
        zip_code: ''
    }

    createAccountWithShippingAddress = {
        type: 'enroll',
        referral_id: '',
        firstname_en: '',
        lastname_en: '',
        email: '',
        phone: '',
        password: '',
        big_address: '',
        zip: '',
        period: ''
    }

    ExcludedFieldsShippingIfCreateAccount = ['fullName']

    ExcludedFieldsShipping = []

    mapFormKeyToFormatKey = {
        street_address: 'address',
        fullName: 'fullname',
        zip_code: 'zip',
        firstname: 'firstname_en',
        lastname: 'lastname_en',
        address: 'big_address',
        province_selection: 'province'
    }

    ArrangeCreateCheckoutForm = [
        'firstname',
        'lastname',
        'email',
        'password',
        'phone',
        'country',
        'street_address',
        'city',
        'state',
        'address',
        'province_selection',
        'zip_code'
    ]

    PaymentStep = {
        payment: 0,
        createOrder: 0,
        autoship: -2,
        updateLog: 0
    }

    breadcrumbOption = [
        {
            caption: 'customise',
            link: '/products',
            paramsRequire: false
        },
        {
            caption: 'signup_shipping',
            link: '/checkout/create-account',
            paramsRequire: true,
            authCaption: 'shipping',
            authLink: '/checkout/shipping'
        },
        {
            caption: 'purchase',
            link: '/checkout/payment',
            paramsRequire: true
        }
    ]
    // [delivery, sameday]
    shippingMethods = [
        {
            id: 1,
            api_value: 'delivery',
            title: 'Standard delivery',
            dict_key: 'standard_delivery',
            for_status: [],
            options: [
                {
                    id: 1,
                    title: 'Credit card',
                    dict_key: 'creditcard',
                    for_status: [],
                    value: 'creditcard'
                },
                {
                    id: 1,
                    title: 'GCash',
                    dict_key: 'g_cash',
                    for_status: [],
                    value: 'g_cash'
                }
            ]
        },
        {
            id: 2,
            api_value: 'sameday',
            title: 'Same Day Delivery',
            dict_key: 'same_day_delivery',
            for_status: [],
            options: [
                {
                    id: 1,
                    title: 'Credit card',
                    dict_key: 'creditcard',
                    for_status: [],
                    value: 'creditcard'
                },
                {
                    id: 1,
                    title: 'GCash',
                    dict_key: 'g_cash',
                    for_status: [],
                    value: 'g_cash'
                }
            ]
        }
    ]

    orderSummaryConfig = {
        subTotal: {
            display: true,
            dict_key: 'subtotal'
        },
        shipping: {
            display: true,
            dict_key: 'shipping'
        },
        productCredit: {
            display: true,
            dict_key: 'product_credit',
            icon: true,
            allowToUse: []
        },
        vat: {
            display: false,
            dict_key: 'vat',
            value: 0.2 // 20%
        },
        shippingWithoutMethod: {
            display: true,
            displayStep: ['payment'],
            dict_key: 'shipping'
        }
    }

    landingLayouts = [
        {
            nameComponent: 'banner',
            titleContent: 'mainBanner',
            style: {}
        },
        {
            nameComponent: 'ourApproach',
            titleContent: 'ourApproach',
            style: {
                minHeight: '0px'
            }
        },
        {
            nameComponent: 'theProducts',
            titleContent: 'theProducts',
            style: {
                backgroundColor: '#F1F6FB'
            }
        },
        {
            nameComponent: 'thePractice',
            titleContent: 'thePractice',
            style: {}
        },
        {
            nameComponent: 'howItWorks',
            titleContent: 'howItWorks',
            style: {
                backgroundColor: '#FBF7F1'
            }
        },
        {
            nameComponent: 'whatExpertsSay',
            titleContent: 'expertsSay',
            style: {}
        },
        {
            nameComponent: 'realResults',
            titleContent: 'realResults',
            style: {
                backgroundColor: '#F2F9F8'
            }
        }
    ]

    productLayouts = [
        {
            nameComponent: 'productDetailSelection',
            titleContent: 'productDetailSelection',
            style: {}
        },
        {
            nameComponent: 'theProducts',
            titleContent: 'theProducts',
            style: {}
        },
        {
            nameComponent: 'frequentlyAskedQuestions',
            titleContent: 'questions',
            style: {
                backgroundColor: '#F6F9FC'
            }
        }
    ]

    MapAutoAddressToForm(form, autoAddressData) {
        const swappedValuesAsKeys = ObjectHelpers.swapKeysAndValues(this.autoAddressKeyToForm)
        return form.map(item => {
            if (swappedValuesAsKeys[item.key]) {
                if (autoAddressData.data) {
                    item.value = autoAddressData.data[swappedValuesAsKeys[item.key]]
                }
            }

            return item
        })
    }

    FormToFormat(type, form) {
        form.map(item => {
            if (this.mapFormKeyToFormatKey[item.key]) {
                if (this.toFormat[type]) {
                    runInAction(() => {
                        this.toFormat[type][this.mapFormKeyToFormatKey[item.key]] = item.value
                    })
                }
            } else {
                if (this.toFormat[type]) {
                    runInAction(() => {
                        this.toFormat[type][item.key] = item.value
                    })
                }
            }

            return item
        })
    }

    SetUuid(value) {
        this.uuid = value
    }

    GetUuid() {
        return this.uuid
    }

    GetFormat(type) {
        return this.toFormat[type]
    }

    GetPaymentStep() {
        return this.PaymentStep
    }

    GetBreadcrumbOption() {
        return this.breadcrumbOption
    }

    GetShippingMethod() {
        return this.shippingMethods
    }

    GetOrderSummaryConfig() {
        return this.orderSummaryConfig
    }

    SetPaymentStep(key, value) {
        this.PaymentStep[key] = value
    }

    GetLandingLayout() {
        return this.landingLayouts
    }

    GetProductLayout() {
        return this.productLayouts
    }

    SetPaymentMethod(value) {
        // if (!value) {
        //     this.GetCreditCardForm().map(item => {
        //         console.log('item', item)
        //         return this.SetCreditCard(item, '')
        //     })
        // }
        this.paymentMethod = value
    }

    GetPaymentMethod() {
        return this.paymentMethod
    }

    StatesList() {
        let items = []
        get(PHLProvinceList, StoreTranslate.CurrentLanguage(), []).map(raw => {
            items.push({
                text: raw,
                value: raw,
                html: `<span>${raw}</span>`
            })
        })
        return items
    }

    AddressOptionList(key) {
        let items = []
        get(PHLProvinceList, StoreTranslate.CurrentLanguage(), []).map(raw => {
            items.push({
                text: raw,
                value: raw,
                html: `<span>${raw}</span>`
            })
        })
        return items
    }

    AddressOptionListSameDay(key) {
        let items = []
        get(PHLProvinceListSameDay, StoreTranslate.CurrentLanguage(), []).map(raw => {
            items.push({
                text: raw,
                value: raw,
                html: `<span>${raw}</span>`
            })
        })
        return items
    }

    SpecialAreasDeliveryFeeCondition(step) {
        if (!this.orderSummaryConfig.shippingWithoutMethod.displayStep.includes(step)) return 0

        if (isEmpty(storeFormat.GetFormatData('formatProducts'))) return 0

        return storeFreightCharge.GetFreightCharge()
    }

    /**
     *
     * @param {*} uuid
     * @param {object} detailPage { products, detailPage, isSubscribe, isEnroll, type}
     * @param {*} CreditCardEncrypted
     */
    async SubmitPayment(uuid, detailPage, CreditCardEncrypted, products) {
        try {
            const Pay = new Payment(uuid, detailPage)
            const responsePreparePayment = await Pay.PreparePaymentData()

            const allowCreditEnable = StoreAuth.IsAllowCredit()

            if (allowCreditEnable && storeCredit.GetCreditData()?.creditUsed > 0) {
                Pay.hydra.transactions = {
                    items: [CreditCardEncrypted, storeCredit.ProductCreditUsed()]
                }
            } else {
                Pay.hydra.transactions = {
                    items: [CreditCardEncrypted]
                }
            }

            await Pay.PostToHydra()
            Pay.SendEmail()
            Pay.SendSms()

            await Pay.UpdateToLog()

            const responsePaymentDetail = await PaymentAPIs.GetPaymentDetail(responsePreparePayment.data.data.payment_id)
            runInAction(() => {
                StorePaymentStep.PaymentDone = true
            })
            // try {
            //     TrackingEvents.PurchaseEvent({
            //         transaction_id: responsePreparePayment.data.data.payment_id,
            //         value: Pay.ushop.total_price,
            //         currency: 'AUD',
            //         category: Pay.ushop.shipment_purchas_option,
            //         items: products
            //     })
            // } catch (e) {
            //     console.error('TrackingEvents.PurchaseEvent', e)
            // }
            return {
                success: true,
                payment_id: responsePreparePayment.data.data.payment_id,
                order_number: responsePaymentDetail.data.order_number
            }
        } catch (error) {
            console.error(error)
            runInAction(() => {
                StorePaymentStep.PaymentDone = true
            })

            return { success: false }
        }
    }

    async CreateAccount(uuid, accForm, shippingForm, referral, cartItems, shippingMethod) {
        try {
            const forms = Object.assign(this.createAccountWithShippingAddress, accForm)
            const returnShippingAddress = {}
            shippingForm.map(item => {
                if (this.ExcludedFieldsShippingIfCreateAccount.includes(item.key)) {
                    return false
                }
                if (this.mapFormKeyToFormatKey[item.key]) {
                    forms[this.mapFormKeyToFormatKey[item.key]] = item.value
                    returnShippingAddress[this.mapFormKeyToFormatKey[item.key]] = item.value
                } else {
                    forms[item.key] = item.value
                    returnShippingAddress[item.key] = item.value
                }
            })

            if (referral) {
                forms.referral_id = referral
            } else {
                delete forms.referral_id
            }

            delete forms.country

            const periodResponse = await FeelGreatAPI.GetPeriod('AU')
            forms.period = periodResponse.data.entryPeriod
            forms.uuid = uuid
            forms.shipment_options = shippingMethod?.api_value
            forms.shipment_packs = JSON.stringify(cartItems)
            const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/hot/formatV2/PHL/feelGreat/createAccount${
                ServerEnv.ENV === 'DEV' ? '?dev=1' : '?emailDelay=1h'
            }`
            const response = await axios.post(url, forms)

            return {
                ...response.data,
                form: forms
            }
        } catch (e) {
            console.error(e)

            return {
                success: false
            }
        }
    }

    constructor() {
        StorePaymentStep.SetPaymentSteps(this.PaymentStep)
        makeAutoObservable(this)
    }
}

export default Philippines
